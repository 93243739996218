<template>
  <div>
    <el-card style="animation: article 1s;">
      <div class="note-layout">
        <div class="note info">
          <i class="fa-solid fa-circle-info"></i>
          {{ "友链交换 ~ 本站示例" }}
        </div>
        <!--friend表第一条记录必须为自己，否则展示出错-->
        <div class="content" v-if="friendList !== null">
          <p>名称：{{friendList[0].nickname}}</p>
          <p>简介：{{friendList[0].description}}</p>
          <p>站点：{{friendList[0].website}}</p>
          <p>头像：{{friendList[0].avatar}}</p>
        </div>
      </div>
      <div class="friend-wrapper">
        <div class="friend" v-for="item in friendList">
          <a :href="item.website" target="_blank" ref="external nofollow noopener">
            <div class="top-wrapper">
              <img :src=item.avatar>
              {{ item.nickname }}
            </div>
            <div class="bottom-wrapper">
              {{ item.description }}
            </div>
          </a>
        </div>
      </div>
      <div class="note-layout">
        <div class="note info">
          <i class="fa-solid fa-circle-info"></i>
          {{ "友链交换 ~ 要求" }}
        </div>
        <div class="content">
          <p>• 保持长期稳定运行，站点体验良好</p>
          <p>• 内容以原创为主，尊重作品版权，健康向上</p>
          <p>• 不定期审查站点，不合规者将移除友链，恕不另行通知( ﾟ∀。)</p>
          <p>• 友链信息请提交至本页评论区，收到博主回复后再于贵站添加本站链接</p>
        </div>
      </div>

    </el-card>
    <el-card id="comment" style="animation: article 1s;margin-top: 40px">
      <CommentList/>
    </el-card>
  </div>
</template>

<script>
import CommentList from "@/components/comment/CommentList";

export default {
  name: "Friends",
  components:{CommentList},
  data() {
    return {
      friendList: null
    }
  },
  created() {
    this.getFriendList()
  },
  methods: {
    getFriendList() {
      this.$request.get("/friend").then(res => {
        this.friendList = res.data
        this.$store.commit("setHeaderInfo", {total: res.data.length})
      })
    }
  }
}
</script>

<style scoped>
/deep/ #comment .el-card__body {
  padding: 40px 50px;
}
.note-layout {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0 10px;
}

.info {
  border-left: 5px solid #428bca;
  padding-left: 3em;
}

.note {
  position: relative;
  display: flex;
  padding: 15px;
  border-radius: 3px 3px 0 0;
  background: #caedf9;;
}

.fa-circle-info {
  color: #428bca;
  font-size: 24px;
  margin-right: 20px;
}

.content {
  position: relative;
  background: #eef7fa;
  color: #00a8e8;
  border-radius: 0 0 8px 8px;
}

.content p {
  margin-left: 20px;
}
.friend-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.friend {
  display: inline-block;
  width: 31%;
  margin: 10px 0;
  padding: 15px 10px 0 10px;
  border: 2px solid #e5e7eb;
  transition: transform 0.25s, box-shadow 0.25s, -webkit-transform 0.25s;
  border-radius: 8px;
}

.friend:hover{
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 34px 20px -24px rgb(136 161 206 / 30%);
}
.friend img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border: 1px solid #a59bdf;
  border-radius: 50%;
}

.top-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #cb97a9;
  font-size: 20px;
  color: #f78c93;
  padding-bottom: 12px;
}

.bottom-wrapper {
  font-size: 15px;
  color: #ec947a;
  margin: 12px 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>